.cart {
	.totals {
		float: right;
	}

	.checkout-types {
		list-style: none;
		margin: $default-spacing 0px $default-spacing 0px;
		padding: 0px;

		li {
			float: right;
		}
	}

	.discount {
		margin-bottom: $default-spacing;
	}

	.shipping-country {
		display: block;
		overflow: hidden;

		span {
			float: left;
		}

		select {
			display: inline-block;
			float: right;
			//width: auto;
			width: calc(100% - 100px);
			border: none;
			background: #ddd;
			padding: 0px 0px 0px 2px;
			height: 25px;
			line-height: 25px;
		}
	}

	.discount {
		display: none;
	}

	.crosssell {
		margin-top: $default-spacing;
	}
}

#shopping-cart-totals-table {
	//width: 450px;

	tr {
		td {
			height: 25px;
			line-height: 25px;
		}

		td:first-child {
			padding-right: 0.5em;
		}
	}
}

@media (max-width: $screen-xs-max) {
	.cart {
		.shipping-country {
			select {
				//width: 100%;
			}

		}
	}

	#shopping-cart-totals-table {
		width: 100%;

		td {
			width: 20%;
		}
		td:first-child {
			width: 80%;
		}
	}

	#shopping-cart-table {
		width: 100%;
		
		thead {
			display: none;
		}

		tbody {
			tr {
				border-bottom: 1px solid #bbb;
			}
			margin-bottom: $default-spacing;
		}

		tfoot {
			tr {
				td {
					width: 100%;
				}
			}
		}

		td {
			width: 30%;
			display: block;
			float: left;
			border: none;
		
			&.image {
				display: none;
			}

			&.name,
			&.wishlist {
				width: 100%;
			}

			&.edit {
				display: none;
			}

			&.trash {
				width: 10%;
			}
		}
	}

	.cart-actions {
		margin: $default-spacing/2 0px $default-spacing 0px ;

		.btn {
			margin-top: $default-spacing/2;
			width: 100%;
		}
	}
}