
.product-view {

	.attribute {
		display: block;
		line-height: 25px;
	}

	.video-preview {
		margin-bottom: $default-spacing;
	}

	.attribute-block {
		margin-bottom: 25px;

		h3 {
			font-family: $font-family-sans-serif;
			font-size: $font-size-base;
			line-height: 25px;
			margin: 0px;
		}

		a {
			text-decoration: none;
		}
	}


	.add-to-cart {
		//margin-bottom: $default-spacing;

		.to-cart-inner {
			padding-left: $default-spacing/2;
		}

		.price,
		.delivery_status {
			font-size: 30px;
			font-family: $text-condensed-family;
			line-height: 40px;
			font-weight: normal;
		}

		.tax-details {
			display: none;
		}

		.price-box {
			margin: 0px 0px $default-spacing 0px;

			span {
				display: inline-block;
			}

			.price-label {
				display: none;
			}

			.old-price {
				display: none;
			}
		}

		button {
			border-radius: 0px;
			border: none;
			color: transparent;
			background: $color-dark-gray url('../images/buttons.png') no-repeat center center;
			width: 233px;
			height: 40px;
			background-size: 466px 160px;

			span {
				display: none;
			}

			&.btn-cart  {
				background-position: 0px 0px;

				&:hover {
					background-position: 0px -40px;
				}
			}

			&.btn-request  {
				background-position: 0px -80px;

				&:hover {
					background-position: 0px -120px;
				}
			}
		}
	}


	.add-to-wishlist {
		height: $default-spacing*3;

		& > div {
			position: relative;
		}

		.price-box {
			position: absolute;
			top: 26px;

			.price-label {
				display: none;
			}
			.circa-price {
				display: none;
			}

			.price {
				display: none;
			}
			
			.old-price {
				line-height: 1em;
				margin: 0px;

				.price-label:before {
					content: "(";
				}

				.price:after {
					content: ")";
					margin-left: -0.1em;
				}

				span {
					display: inline-block;
					line-height: 1em;

					font-size: 14px;
					font-family: $font-family-sans-serif;
				}

			}
		}

		.tax-details {
			position: absolute;
			top: 0px;

			//margin: $default-spacing 0px 0px 0px;
		}
	}

	.share-links {
		list-style: none;
		margin: 0px;
		padding: 0px;

		li {
			display: inline-block;
			overflow: hidden;
			/*
			line-height: 25px;

			span {
				float: left;
				margin: 0px $default-spacing/2 0px 0px;	
			}

			a {
				text-decoration: none;
			}*/
		}
	}

	.links {
		margin: 0px 0px $default-spacing*2 0px;
	}

	.text-boxes {
		margin-bottom: $default-spacing*2;

		.text-box {
			margin-bottom: $default-spacing;
		}
	}

	.box-attachments {
		ul {
			list-style: none;
			margin: 0px;
			padding: 0px;

			li {
				overflow: hidden;

				span, a {
					float: left;
					line-height: 25px;
				}

				.social-icon {
				}
			}
		}
	}
}

@media screen and (max-width: $screen-sm-max) {
	.product-view {
		.to-cart-inner {
			padding-left: 0px !important;
		}
		
		.btn-request {
			margin-bottom: $default-spacing;
		}

		.text-boxes {
			margin-bottom: 0px;

			.text-box {
				margin-bottom: 0px;
			}
		}
	}
}

.catalog-product-view {
	.flexslider {
		margin-bottom: $header-spacing / 2;
	}
}

html[lang="en"] {
	.product-view {
		.add-to-cart {
			button {
				&.btn-cart  {
					background-position: -233px 0px;

					&:hover {
						background-position: -233px -40px;
					}
				}

				&.btn-request  {
					background-position: -233px -80px;

					&:hover {
						background-position: -233px -120px;
					}
				}
			}
		}
	}
}

.imgbox {
	display: none;
	opacity: 0;
	transition: opacity .5s ease-in-out;

	background: #fff;
	-webkit-box-shadow: 7px 7px 5px 0px rgba(0,0,0,0.75);
	-moz-box-shadow: 7px 7px 5px 0px rgba(0,0,0,0.75);
	box-shadow: 7px 7px 5px 0px rgba(0,0,0,0.75);

	//border: 2px solid #444;
	border-radius: 1px;

	position: fixed;
	left: 50%;
	top: 50%;

	max-width: 800px;

	z-index: 1200;
	padding: $default-spacing/2 $default-spacing $default-spacing $default-spacing;

	&.is-visible {
		opacity: 1;
	}

	.title {
		overflow: hidden;
		margin-bottom: $default-spacing/2;

		.name {
			float: left;
			line-height: 24px;
		}

		.btn-close {
			float: right;
			font-size: 24px;
    		cursor: pointer;
		}
	}
}

@media screen and (max-width: $screen-sm-max) {

	.imgbox {
		max-width: 400px;
	}
}