.navbar-brand {
	padding: 30px 15px 30px 30px;
	height: auto;

	.logo {
		display: block;
		height: 30px;
		width: 220px;
		background: url('../images/icons_black_30.png') no-repeat center center;
		//background-size: 330px 60px;
		background-position: -30px 0px;
	}

	img {
		height: 30px;
		width: auto;
		display: none;
	}
}

@media (max-width: $screen-sm-max) {
	.navbar-brand {
		padding: 15px;

		.logo {
			height: 25px;
			width: 200px;
			background-size: 275px 50px;
			background-position: -25px 0px;
		}
	}
}

.dark-slide  {
	.header-desktop {
		.navbar {
			.navbar-nav > li > a {
				color: #fff;
			}
		}

		.flex-control-paging li a {
			background: #ccc;
			background: rgba(255, 255, 255, 0.6)
		}

		.flex-control-paging li a.flex-active {
			background: #fff;
			background: rgba(255, 255, 255, 0.9)
		}

		.navbar-brand {
			.logo {
				background-image: url('../images/icons_white_30.png');
			}
		}

		.icon {
			background-image: url('../images/icons_white_25.png');

			&.icon-medium {
				background: url('../images/icons_white_30.png');
			}
		}
	}
}

.scrolled,
.forced-header-bg {
	&.dark-slide {
		.header-desktop {
			.navbar {
				background-color: rgba($color-dark-gray, 0.95);
			}
		}
	}

	.header-desktop {
		.navbar {
			background-color: rgba(#fff, 0.95);
		}
	}
}


.category-kuenstler {
	.header-desktop {
		.navbar {
			background-color: #fff;
		}
	}
}

.header-mobile {
	display: none;

	.navbar {
		background: #fff;
	}

	.navbar-brand {
		height: auto;
  		padding: $navbar-padding-vertical $navbar-padding-horizontal;
		
		img {
			height: auto;
			max-width: 200px;
		}
	}

	.navbar-header {
		float: left;
	}

	.navbar-right {
		float: right;
		margin-top: 0px;
		margin-bottom: 0px;
		margin-right: 0px;

		li {
			float: left;
		}
	}
}

@media (max-width: $screen-sm-max) {
	.header-desktop {
		display: none;
	}

	.header-mobile {
		display: block;
	}
}