
.navbar {
	border: none;
	border-radius: 0px;
	//margin: 0px;
	min-height: 60px;


	max-width: $page-max-width;
	margin: 0px auto;

	-webkit-transition:background-color 200ms ease-in-out;
	-moz-transition:background-color 200ms ease-in-out;
	-o-transition:background-color 200ms ease-in-out;
	transition:background-color 200ms ease-in-out;

	.navbar-nav {
		& > li > a {
			font-family: $text-condensed-family;
			font-weight: 300;
			font-size: 23px;
			padding: 40px 10px 15px 10px;

		}

 		& > .active > a {
 			color: #000;
 			font-weight: normal;
 		}
	}

	.navbar-right {
		& > li {
			& > a {
				padding: 35px 5px 15px 5px;

				&.dropdown-toggle {
					padding: 30px 30px 15px 30px;
				}
			}

			&.language_switch  {
				a {
					font-size: 23px;
					padding-top: 40px;
				}
			}
		}
	}

	.cart,
	.wishlist {
		position: relative;

		.icon {
			transition: opacity .1s ease-in-out;
			opacity: 1;
		}

		/*
		.amount {
			border-radius: 10px;
			height: 20px;
			width: 20px;
			background: #fff;

			position: absolute;
			bottom: 0px;
			left: 0px;
			font-size: 8px;
		}

		.amount {
			color: #fff;
			position: absolute;
			bottom: 13px;
			left: 16px;
			font-size: 10px;
		}*/

		&.has-amount{
			&:hover {
				.icon {
					opacity: 0.6;
				}
				.amount {
					opacity: 1;
				}
			}
		}

		.amount {
			position: absolute;
			left: 0px;
			bottom: 0px;
			width: 100%;
			//height: 100%;
			//background: #fff;
			color: #000;
			text-align: center;
			padding: 0px 0px 15px 0px;
			opacity: 0;
			font-weight: bold;

			transition: opacity .1s ease-in-out;
		}
	}
}

@media (max-width: $screen-sm-max) {

	.navbar {

		.navbar-right {	
			& > li > a {
				padding: 25px 10px;
			}
		}

	}
}

/*
.dark-slide {
	.cart,
	.wishlist {
		.amount {
			background: none;
		}
	}
}*/