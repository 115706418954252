
@media screen and (max-width: $screen-xs-max) {
	.order-details {
		colgroup {
			display: none;
		}
		thead {
			display: none;
		}

		tbody {
			tr {

				td {
					width: 20%;
					display: block;
					float: left;
				
					&.sku {
						width: 0%;
					}

					&.name {
						width: 100%;
					}
				}
			}
		}

		tfoot {
			tr {
				td {
					width: 20%;
				
					&:first-child {
						width: 80%;
					}
				}
			}
		}

	}		
}