.box,
.remember-me-popup {
	.box-title,
	.panel-heading {
		overflow: hidden;

		h3 {
			float: left;
		}

		a {
			float: right;
			font-size: 12px;

			&:before {
				content: "[";
			}

			&:after {
				content: "]";
			}
		}
	}
}