@import "fonts/flama_condensed";
@import "vars";
@import "bootstrap";
@import "flexslider";
@import "icons";
@import "components/box";
@import "components/blocks";
@import "components/dialog";
@import "components/mobile-navigation";
@import "components/navbar";
@import "components/header-image";
@import "components/summary";
@import "modules/header";
@import "modules/opc";
@import "modules/cart";
@import "modules/product-view";
@import "modules/product-grid";
@import "modules/artists";
@import "modules/search";
@import "modules/upsell";
@import "modules/blog";
@import "modules/wishlist";
@import "modules/account";
@import "modules/sales";

* {
	-webkit-font-smoothing: antialiased;
}

body {
	padding: $body-padding-top 0px 0px 0px;
	overflow-y: scroll;
	max-width: $page-max-width;
	margin: 0px auto;
	//background-color: #f8f8f8;

	&.has-flexslider,
	&.has-header-image {
		padding-top: 0px;
	}

	line-height: 1.5em;
}

.page {
	background-color: #fff;
}

@media (max-width: $screen-xs-max) {
	body {
		padding-top: $header-height-mobile+$default-spacing;

		&.has-flexslider,
		&.has-header-image {
			padding-top: $header-height-mobile;
		}
	}
}

body.show-mobile-menu {
	.page {
		display: none;
	}
}

body.page-popup {
	padding: $default-spacing;
}

h1, h2, h3, h4, h5 {
	line-height: normal;
	letter-spacing: 0.03em;	
	margin-top: 0px;
	margin-bottom: $default-spacing/2;
}

h1, h2 {
	margin-bottom: $default-spacing;
}

label {
	em {
		margin-right: 0.25em;
	}
}

hr {
	border-color: #ccc;
}

table {
	width: 100%;

	td {
		vertical-align: top;
		
		&.col-50 {
			width: 50%;
			padding: 0px $default-spacing/2;
		}

		&.col-66 {
			width: 66%;
			padding: 0px $default-spacing/2;
		}

		&.col-33 {
			width: 33%;
			padding: 0px $default-spacing/2;

		}

		&:first-child {
			padding-left: 0px;
		}

		&:last-child {
			padding-right: 0px;
		}
	}
}

@media (max-width: $screen-xs-max) {
	table {
		td {
			word-break: break-all;

			&.col-33,
			&.col-50,
			&.col-66 {
				display: block;
				width: 100%;
				padding: $default-spacing/2 0px;
			}

			&.col-33 {
				text-align: center;

				.img-responsive {
					margin: 0 auto;
				}
			}
		}
	}
}

.category-presse,
.category-press {
	.col-33 {
		padding: 0px $default-spacing/2;
		
		img {
			width: 70%;
			margin: 0px auto $default-spacing auto;
		}
	}
}

@media (max-width: $screen-xs-max) {
	.category-presse,
	.category-press {
		.col-33 {
			padding: $default-spacing/2 0px;
			
			img {
				width: auto;
				margin: 0 auto;
			}
		}
	}
}

.a-right {
	text-align: right;
}

.a-left {
	text-align: left;
}

.a-center {
	text-align: center;
}

.buttons-set {
	overflow: hidden;

	.f-left {
		float: left;
	}
	
	.required {
		display: block;
		float: none;
		clear: both;
		margin-bottom: $default-spacing/4;
	}

	.btn {
		float: right;
		margin-left: $default-spacing/2;
		
		small {
			display: none;
		}
	}

	.btn-back {
		float: left;
		margin-left: 0px;
	}

	.button {
		@extend .btn;
		@extend .btn-default;
	}
}


a.btn {
	text-decoration: none;
}

.page-title {
	width: 100%;
	overflow: hidden;

	&.title-buttons {
		h1, h2, h3 {
			float: left;
		}

		.btn, a  {
			float: right;
		}

		a {
			@extend .btn;
			@extend .btn-default;
		}
	}
}

.product-name {
	a {
		text-decoration: none;
	}

	span {
		display: block;
	}
}

.no-display {
	display: none;
}

body > .container {
	min-height: 780px;
}

.tax-details {
	.shipping-cost-details,
	.tax-separator {
		display: none;
	}
}

.footer {
	height: $footer-height;
	background-color: $color-dark-gray;
	color: #fff;
	letter-spacing: 0.5px;
	font-weight: normal;
    margin: $default-spacing * 3 -15px 0px -15px;
	
	.nav {
		& > li {
			& > a {
				display: block;
				padding: 30px 15px !important;
				font-size: 23px !important;
			}
		}
	}

	.logo {
		max-height: 20px;
		width: auto;
		display: block;
	}
}

.hide-footer {
	.footer {
		display: none;
	}
}

.footer-fixed {
	padding-bottom: $footer-height+$default-spacing;
	
	.footer {
		position: fixed;
		bottom: 20px;
		left: 15px;
		width: 100%;
		margin-top: 0px;
	
	}
}

@media (max-width: $screen-sm-max) {
	.footer {
		.nav {
			& > li {
				& > a {
					padding: 30px 10px !important;
				}
			}
		}
	}
}

.sidebar {
	&.sidebar-fixed {
		position: fixed;
		max-width: 280px;
	}

	&.sidebar-right {
		//margin-left: 890px;
	}

	.sort-by {
		margin: 0px 0px $default-spacing 0px;
		color: #aaa;

		select {
			display: inline-block;
			width: auto;
			border: none;
			padding-left: 0px;
			color: #aaa;
			cursor: pointer;
		}
	}
}

.subnavi,
.sidebar .subcategories {
	margin: 0px;
	padding: 0px;
	list-style: none;
	max-width: 250px;

	li {
		margin: 0px 0px $default-spacing*0.75 0px;

		&.active {
			a {
				font-weight: normal;
			}
		}

		a {
			text-decoration: none;
			font-family: $text-condensed-family;
			font-weight: 300;
			font-size: 23px;
		}
	}
}

@media (max-width: $screen-xs-max) {
	.sidebar {
		&.sidebar-fixed {
			position: relative;
			max-width: none;
		}

		.subcategories {
			display: none;
		}
	}
}

.subnavi-fixed {
	.subnavi {
		position: fixed;
		top: 140px;
	}
}

@media (max-width: $screen-xs-max) {
	.subnavi-fixed {
		.subnavi {
			position: relative;
			top: auto;
		}
	}
}

.window-overlay {
	background-color: rgba(255, 255, 255, 0.8);
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
}

.remember-me-popup {
	position: fixed;
	top: 200px;
	width: 50%;
	left: 25%;
}

.social-header {
	text-align: center; 
	width: 100%;
	line-height: 50px;
	display: block;
	margin: $default-spacing*2 0px $default-spacing 0px;

	background-color: #ccc;
	color: #000;

	a {
		text-decoration: none;
		font-family: "Flama Condensed", Sans-serif;
	    font-weight: 300;
	    font-size: 23px;
	}
}

@media (max-width: $screen-xs-max) {
	.social-header {
		margin-top: $default-spacing;
	}
}

.twitter-timeline,
.instagram-stream iframe {
	height: 600px !important;
	width: 100% !important;
}

@media (max-width: $screen-xs-max) {

	#twitter-widget-0,
	iframe.twitter-timeline,
	.instagram-stream iframe {
		max-width: 320px !important;
		width: 320px !important;
	}
}

.cms-home {
	.widget-category-link {
		margin: 0 auto;
		display: block;
		text-align: center;
	}
}

.page {
	padding-left: 15px;
	padding-right: 15px;
}

@media (max-width: $screen-xs-max) {
	.page {
		& > .container {
			margin-right: -15px;
			margin-left: -15px;
		}
	}
}

.vimeo {
	iframe {
		border: none;
	}
}

.fancy-select {
	display: inline-block;
	position: relative;
	cursor: pointer;
	margin-left: 0.5em;
	height: 18px;

	.wrapper {

	.current {
		line-height: 16px;
		display: inline-block;
	}

	.icon {
		background: url('../images/fancy-select.png') no-repeat center center;
		width: 16px;
		height: 16px;
		display: inline-block;
		margin-left: 0.25em;
	}

	}

	select {
		display: none !important;
	}

	&.show-menu {
		ul {
			display: block;
		}
	}

	&.show-scroll {
		ul {
			max-height: 300px;
			overflow-y: scroll;
		}
	}

	ul {
		border: 1px solid #eee;
		display: none;
		list-style: none;
		position: absolute;
		top: 23px;
		left: 0px;
		padding: 5px 10px;
		margin: 0px;
		min-width: 160px;
		background-color: #fff;
		color: #222;
		z-index: 3000;
		
	}
}

.press-download {
	text-align: center;
   	margin-bottom: $default-spacing;

	img {
		width: 70%;
    	margin: 0px auto $default-spacing auto;
	}
}

.price-box .price,
.price-box .circa-price,
.delivery_status {
		font-size: 30px;
		font-family: $text-condensed-family;
		line-height: 40px;
		font-weight: normal;
}

.old-price,
.special-price {
	span {
		display: block;
	}
}

.blocker {
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background-color: rgba(255,255,255,0.6);
	z-index: $zindex-blocker;
	display: none;
}

.show-blocker {
	.blocker {
		display: block;
	}
}

.alert-fixed {
	position: fixed;
	top: 20px;
	left: 50%;
	margin-left: -200px;
	max-width: 400px;
	z-index: 2000;
}

@media (max-width: $screen-xs-max) {
	.catalog-category-view {
		table {
			position: relative;
			//display: block;
			width: 100% !important;
			height: auto !important;

			tr {
				position: relative;
				//display: block;
				width: 100% !important;
				height: auto !important;

				td {
					position: relative;
					//display: block;
					width: 100% !important;
					height: auto !important;
				
					p {
						position: relative;
						//display: block;
						width: 100% !important;
						height: auto !important;
					}
				}
			}
		}
	}
}

.cookie-alert p,
.cookie-alert a,
.cookie-alert a:visited,
.cookie-alert .ca-button {
  font-family: "Pontano Sans", sans-serif !important;
}