.block-account {
	ul {
		list-style: none;
		margin: 0px;
		padding: 0px;
	
		a {
			text-decoration: none;
		}
	}
}

.help-block-error {
	display: none;
}

.has-error {
	.help-block-error {
		display: block;
	}
}