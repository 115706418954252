.opc {
	list-style: none;
	margin: 0px;
	padding: 0px;

	ul {
		list-style: none;
		margin: 0px;
		padding: 0px;
	}

	.section {

		.step-title {
			overflow: hidden;

			span, h2 {
				font-family: $headings-font-family;
				font-size: $font-size-h2;
			}

			span, h2, a {
				float: left;
				display: block;
				line-height: 30px;
				margin: 0px $default-spacing 0px 0px;
			}

			a {
				display: none;
				float: right;
				margin: 0px;
			}
		}
	
		&.allow {
			.step-title {
				a {
					display: block;
				}
			}
		}
	}

	.no-display {
		display: none;
	}


	.checkout-agreements {
		list-style: none;
		margin: 0px;
		padding: 0px;
	}

	.please-wait {
		display: none;
	}
}

.order-review {
	.product-name {
		font-size: $font-size-base;
	}
}

#checkout-progress-wrapper {
	margin-top: 48px;
}

#checkout-review-table-wrapper {
	border: none !important;
	background: none !important;
}

#checkout-review-table {
	tbody {
		td.last {
			text-align: right;
		}
	}

	tfoot tr,
	tr.odd,
	tr.even {
		background-color: transparent !important;
	}
}

#checkout-review-submit {
	border: none !important;
	background: none !important;
}

.validation-advice {
	color: $alert-danger-text;
	padding-top: $default-spacing/4;
}

@media (max-width: $screen-xs-max) {
	.opc {
		.field {
			margin-bottom: $default-spacing;
		}
	}
}
/*
#payment_form_ccsave {
	li {
		margin-bottom: $default-spacing/2;
	}
}

#checkout-step-payment {
	dd {
		margin-left: 35px;
	}
}*/

.sp-methods {
	dd {
		padding-left: $default-spacing !important;
	}
}

.iways_paypalplus_payment {
	display: none;

	&:first-child {
		display: block !important;
	}
}

#checkout-payment-method-load dd.ppp.ppp-selected,
#checkout-payment-method-load dt.ppp.ppp-selected {
	background-color: transparent !important;
}

#payment_form_ccsave {
	li {
		margin-bottom: $default-spacing/2;
	}
}