.dialog-basic-search {

	.form-search {
		position: relative;

		label {
			display: none;
		}
	}
	
	.am_search_popup_triangle {
		display: none;
	}

	.am_search_popup {
		position: absolute;
		width: calc(100% - 40px);
		top: 65px;
		left: $default-spacing;
		background-color: #fff;
		border: none;

		.am_search_popup_inner {
			border: none;
			border-radius: 0px;
			max-height: calc((100vh - 140px)*0.6);
    		overflow-y: scroll;

			a {
				.am_element {
					border-top: 1px solid #ccc;
				}
			
				&:first-child {
					.am_element {
						border: none;
					}
				}
			}

			.am_element {
				background: none;
				overflow: hidden;
				border-top: 1px solid #ccc;
			
				.am_image {
					padding: $default-spacing;
					float: left;

					img {
						-webkit-box-shadow: 2px 2px 10px 2px rgba(46,46,46,0.4);
						-moz-box-shadow: 2px 2px 10px 2px rgba(46,46,46,0.4);
						box-shadow: 2px 2px 10px 2px rgba(46,46,46,0.4);
						border: 0px;

						width: 80px;
						height: auto;
					}
				}

				.am_right {
					border: none;
					float: left;
					padding: $default-spacing;
					text-decoration: none;
				}
			}

			.more_results {
				padding: $default-spacing/2; //0px  $default-spacing/2 $default-spacing/2;
				text-align: center;
				display: block;
			}

			& > div {
				position: absolute;
				bottom: -41px;
				width: 100%;
				background-color: #fff;
			}
		}
	}

	.search-autocomplete {
		position: absolute;
		width: 100% !important;
		top: 45px !important;
		left: 0px !important;
		background-color: #fff;

		.amount {
			display: none;
		}

		ul {
			list-style: none;
			margin: 0px;
			padding: 0px;

			li {
				border-top: 1px solid #ccc;
				padding: $default-spacing/2;
				cursor: pointer;
			}
		}
	}

}

.search-results {
	.page-title {
		text-align: center;
		margin: 0px 0px $default-spacing 0px;
	}

	.result-stats {
		text-align: center;
		margin: 0px 0px $default-spacing*4 0px;
	}
}

@media (min-width: $screen-md-min) {
	.dialog-basic-search {
		.am_search_popup {
			top: 94px;
		}
	}
}

@media (min-width: $screen-lg-min) {
	.dialog-basic-search {

		.am_search_popup {
			.am_search_popup_inner {
				//max-height: none;
				//overflow-y: visible;
	    	}
	    }
	}
}