.welcome-msg {
	margin-bottom: $default-spacing*1.5;
}

.my-account {
		/*
	.buttons-set {
		margin-top: $default-spacing;
		margin-bottom: $default-spacing;
	
		.required {
			margin-bottom: $default-spacing/2;
		}

		.back-link {
			display: block;
			text-align: left;

			a {
				@extend .btn;
				@extend .btn-default;
				float: left !important;
				margin-left: 0px !important
			}
		}
	}*/
	.buttons-set {
		margin-top: $default-spacing;
		margin-bottom: $default-spacing;
		
		.required {
			margin-bottom: $default-spacing/2;
		}

		.back-link {
			display: block;
			text-align: left;

			a {
				@extend .btn;
				@extend .btn-default;
				@extend .btn-back;
			}
		}
	}
}

.addresses-list {
	ol {
		list-style: none;
		margin: 0px;
		padding: 0px;
	}
}

#my-orders-table {
	th, tr {
		td:first-child {
			min-width: 120px;
		}
	}
}