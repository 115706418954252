

.summary-total {
	color: #888;

	&.show-details {
		color: #000;
	}

	.summary-collapse {
		text-decoration: underline;
		cursor: pointer;
	}
}
