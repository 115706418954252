.products-grid {
	overflow: hidden;
	padding: 10px 0px 0px 0px;

	.product {
		display: none;
		text-align: center;
		margin: 0px 0px $default-spacing*2 0px;
		position: relative;
		opacity: 0;
		-webkit-transition:opacity 200ms ease-out;
		-moz-transition:opacity 200ms ease-out;
		-o-transition:opacity 200ms ease-out;
		transition:opacity 200ms ease-out;

		
		a {
			text-decoration: none;
		}

		img {
			-webkit-box-shadow: 2px 2px 10px 2px rgba(46,46,46,0.4);
			-moz-box-shadow: 2px 2px 10px 2px rgba(46,46,46,0.4);
			box-shadow: 2px 2px 10px 2px rgba(46,46,46,0.4);
			margin: 0 auto;
		}

		.overlay {
			position: absolute;
			top: 0px;
			left: 0px;
			width: 100%;
			height: 100%;
			margin: 0 auto;

			display: block;
			color: #fff;
			text-align: center;
			opacity: 0;
			-webkit-transition:opacity 200ms ease-out;
			-moz-transition:opacity 200ms ease-out;
			-o-transition:opacity 200ms ease-out;
			transition:opacity 200ms ease-out;

			.inner {
				position: absolute;
				top: 50%;
				left: 0px;
				width: 100%;
				margin-top: -65px;
				height: 130px;
			}

			&.has-special-price {
				.inner {
					height: 160px;
					margin-top: -80px;
				}
			}
		}

		.price-box {
			display: block;
			margin: 0px 0px $default-spacing*0.75 0px;
			font-weight: normal;
			font-size: 30px;
			text-align: center;
			font-family: $text-condensed-family;


			.old-price {
				display: none;
			}

			.special-price {
				span {
					display: block;

					&.price-label {
						padding-bottom: $default-spacing/2;
					}
				}
			}
		}

		.tax-details,
		.delivery-time {
			display: none;
		}

		.actions {
			margin: 0px 0px $default-spacing*0.75 0px;

			a {
				color: #fff;
				margin: 0px;
			}

			&.actions-bottom {
				margin: 0px;
			}
		}

		.img-wrapper {
			position: relative;

			.overlay {
				cursor: pointer;
				background-color: rgba(0,0,0,0.8);
			}
		}

		.product-name {
			font-size: 20px;
			line-height: 26px;
			margin: $default-spacing 0px 0px 0px;
		}

		&:hover {
			.overlay {
				opacity: 1;
				-webkit-transition:opacity 200ms ease-out;
				-moz-transition:opacity 200ms ease-out;
				-o-transition:opacity 200ms ease-out;
				transition:opacity 200ms ease-out;
			}
		}
	
		&.is-visible {
			opacity: 1;
			-webkit-transition:opacity 200ms ease-out;
			-moz-transition:opacity 200ms ease-out;
			-o-transition:opacity 200ms ease-out;
			transition:opacity 200ms ease-out;
		}
	}
}

.sort-by {
	a {
		display: none;
	}
}

.scroll-loading {
	display: none;
	background: url('../images/ring.gif') no-repeat center center;
	background-size: $default-spacing*3 $default-spacing*3;
	width: $default-spacing*3;
	height: $default-spacing*3;
	margin: 0 auto;
}

.fullscreen-loading {
	display: block;
	position: fixed;
	top: 0px;
	left: 0px;
	z-index: 10000;
	background: url('../images/ring.gif') no-repeat center center;
	background-size: $default-spacing*3 $default-spacing*3;
	margin: 0 auto;
	width: 100%;
	height: 100%;
	background-color: rgba(255,255,255,0.8);
}

@media (min-width: 900px) {
	.category-trouves {
		.category-cms-content {
			width: 75% !important;
		}
	}
}

@media (min-width: 1500px) {
	.category-trouves {
		.category-cms-content {
			width: 50% !important;
		}
	}
}

.category-content-grid  {
	margin-bottom: 20px;
}