
@media (min-width: $screen-xs-min) {
	.box-up-sell-signed,
	.box-up-sell-print {
		.product {
			
			.info {
				text-align: left;
				
				& > a {
					display: none;
				}
				
				.img-wrapper {
					img {
						max-width: 80% !important;
						width: 100% !important;
						margin: 0px 0px 0px 5%;
					}

					.overlay {
						max-width: 80% !important;
						width: 100% !important;
						left: 5% !important;
						margin: 0px !important;
					}
				}
			}
		}
	}

	.box-up-sell {
		.product {
			.info {
				text-align: left;

				.img-wrapper {
					img {
						max-width: 50% !important;
						width: 100% !important;
						margin: 0px;
					}

					.overlay {
						max-width: 50% !important;
						width: 100% !important;
						left: 0px !important;
						margin: 0px !important;
					}
				}
			}
		}
	}
	
	.upsell-bottom  {
		.product {
			.info {
				//text-align: left;
				
				.img-wrapper {
					img {
						max-width: 90% !important;
						width: 90% !important;
					}
				}

				.overlay {
					max-width: 90% !important;
					width: 90% !important;
					left: 5% !important;
					margin: 0px !important;
				}

				.title-2,
				.title-3 {
					font-size: 80%;
				}
			}
		}
	}
}
