.icon {
	display: inline-block;
    background-repeat: no-repeat;
	width: 25px;
	height: 25px;
	background: url('../images/icons_black_25.png');

	&.icon-intense {
		background-image: url('../images/icons_intense_25.png') !important;
	}

	&.icon-light {
		background-image: url('../images/icons_white_25.png');
	}

	&.icon-empty {
		background: none;
		text-align: center;
	}

	&.icon-menu-hamburger {
    	background-repeat: no-repeat;
		background-position:    0px   0px;
	}

	&.icon-search {
    	background-repeat: no-repeat;
		background-position:    0px -25px;
	}

	&.icon-shopping-cart {
    	background-repeat: no-repeat;
		background-position:  -25px -25px;
	}

	&.icon-user {
    	background-repeat: no-repeat;
		background-position:  -50px -25px;
	}

	&.icon-heart {
    	background-repeat: no-repeat;
		background-position:  -75px -25px;
	}

    &.icon-pdf {
    	background-repeat: no-repeat;
    	background-position: -100px -25px;
    }

    &.icon-mail {
    	background-repeat: no-repeat;
    	background-position: -125px -25px;
    }

    &.icon-logo-small {
    	background-repeat: no-repeat;
    	background-position: -150px -25px;
    }

    &.icon-instagramm {
    	background-repeat: no-repeat;
    	background-position: -175px -25px;
    }

    &.icon-facebook {
    	background-repeat: no-repeat;
    	background-position: -200px -25px;
    }

    &.icon-vimeo {
    	background-repeat: no-repeat;
    	background-position: -225px -25px;
    }

    &.icon-twitter {
    	background-repeat: no-repeat;
    	background-position: -250px -25px;
    }

	&.icon-medium {
		width: 30px;
		height: 30px;
		background: url('../images/icons_black_30.png');

		&.icon-light {
			background-image: url('../images/icons_white_30.png');
		}

	    &.icon-menu-hamburger {
			background-position:    0px   0px;
		}

		&.icon-search {
			background-position:    0px -30px;
		}

		&.icon-shopping-cart {
			background-position:  -30px -30px;
		}

		&.icon-user {
			background-position:  -60px -30px;
		}

		&.icon-heart {
			background-position:  -90px -30px;
		}

	    &.icon-pdf {
	    	background-position: -120px -30px;
	    }

	    &.icon-mail {
	    	background-position: -150px -30px;
	    }

	    &.icon-logo-small {
	    	background-position: -180px -30px;
	    }

	    &.icon-instagramm {
	    	background-position: -210px -30px;
	    }

	    &.icon-facebook {
	    	background-position: -240px -30px;
	    }

	    &.icon-vimeo {
	    	background-position: -270px -30px;
	    }

	    &.icon-twitter {
	    	background-position: -300px -30px;
	    }
	}

	&.icon-large {
		width: 50px;
		height: 50px;
		background: url('../images/icons_black_50.png');

		&.icon-light {
			background-image: url('../images/icons_white_50.png');
		}

		&.icon-menu-hamburger {
			background-position:    0px   0px;
		}

		&.icon-search {
			background-position:    0px -50px;
		}

		&.icon-shopping-cart {
			background-position:  -50px -50px;
		}

		&.icon-user {
			background-position: -100px -50px;
		}

		&.icon-heart {
			background-position: -150px -50px;
		}

	    &.icon-pdf {
	    	background-position: -200px -50px;
	    }

	    &.icon-mail {
	    	background-position: -250px -50px;
	    }

	    &.icon-logo-small {
	    	background-position: -300px -50px;
	    }

	    &.icon-instagramm {
	    	background-position: -350px -50px;
	    }

	    &.icon-facebook {
	    	background-position: -400px -50px;
	    }

	    &.icon-vimeo {
	    	background-position: -450px -50px;
	    }

	    &.icon-twitter {
	    	background-position: -500px -50px;
	    }
	}
}