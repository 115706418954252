.wishlist {
	list-style: none;
	margin: 0px;
	padding: 0px;
	
	.wishlist-item {
		margin-bottom: 20px;
	
		&:last-child {
			hr {
				display: none;
			}
		}
	
		.tax-details,
		.delivery_status {
			display: block;
			margin-bottom: $default-spacing/2;
		}
	
		.btn {
			margin-top: $default-spacing/2;
			width: 100%;
		}

		textarea {
			min-height: 135px;
		}
	}
}

.my-wishlist {
}