.sliding-menu {
	display: block;
	overflow: hidden; 
	background: #fff;

	ul { 
		float: left; 
		margin: 0px; 
		padding: 0px;
	}

	li { 
		list-style: none; 
		margin: 0px; 
		padding: 0px;
		///border-top: 1px solid #eee;

		&:first-child {
			border: none;
		}
	}

	a { 
		display: block; 
		//padding: 1em; 
		padding: 0px;
		text-decoration: none;
		font-family: $text-condensed-family;
		font-size: $sliding-menu-text-size;
		line-height: $sliding-menu-line-height;
		width: 100%;
		overflow: hidden;
		padding: $default-spacing/2 $default-spacing;

	}

	/*
	a:hover { 
		background: #333; 
		color: #FFF; 
	}
	a.nav:before { 
		content: '\3009'; 
		float: right; 
		margin-left: 1em; 
	}*/

	a.nav {
		&:before {
			float: right;
			@extend .glyphicon;
			@extend .glyphicon-menu-right;
			line-height: $sliding-menu-line-height;
		}
	}

	a.back { 
		//background: #555; 
		//color: #FFF; 

		&:before {
			@extend .glyphicon;
			@extend .glyphicon-menu-left;
			line-height: $sliding-menu-line-height;
			float: left;
			margin-right: 0.5em;
		}
	}
	/*
	a.back:before { 
		content: '\3008'; 
		float: left; 
		margin-right: 1em; 
	}
	*/

	.has-icon {
		overflow: hidden;

		span {
			float: left;
		}

		.icon {
			margin-right: 0.5em;
		}
	}

	.separator {
		padding: 0px 0px $default-spacing/2 0px;
			
		margin: 0px $default-spacing 0px $default-spacing;
		display: block;
		width: calc(100% - #{$default-spacing*2});
		border-top: 1px solid #222;
	}
}

.mobile-navigation-wrapper {
	display: none;
	position: fixed;
	height: calc(100% - #{$header-height-mobile});
	top: $header-height-mobile;
	left: 0px;
	width: 100%;
	background: #fff;
	z-index: 2000;
	overflow-y: scroll;
}

.show-mobile-navigation {
	/*
	.mobile-navigation-wrapper {
		display: block;
	}*/

	.page {
		display: none;
	}
}