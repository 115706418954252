@font-face {
	font-family: 'Flama Condensed';
	src: url('../fonts/FlamaCondensed/Flama Cond Light/FlamaCond-Light.eot');
	src: url('../fonts/FlamaCondensed/Flama Cond Light/FlamaCond-Light.eot') format('embedded-opentype'),
	     url('../fonts/FlamaCondensed/Flama Cond Light/FlamaCond-Light.woff') format('woff'),
	     url('../fonts/FlamaCondensed/Flama Cond Light/FlamaCond-Light.ttf') format('truetype'),
	     url('../fonts/FlamaCondensed/Flama Cond Light/FlamaCond-Light.svg') format('svg');

	font-weight: 300;
}

@font-face {
	font-family: 'Flama Condensed';
	src: url('../fonts/FlamaCondensed/Flama Cond Basic/FlamaCond-Basic.eot');
	src: url('../fonts/FlamaCondensed/Flama Cond Basic/FlamaCond-Basic.eot') format('embedded-opentype'),
	     url('../fonts/FlamaCondensed/Flama Cond Basic/FlamaCond-Basic.woff') format('woff'),
	     url('../fonts/FlamaCondensed/Flama Cond Basic/FlamaCond-Basic.ttf') format('truetype'),
	     url('../fonts/FlamaCondensed/Flama Cond Basic/FlamaCond-Basic.svg') format('svg');
}

@font-face {
	font-family: 'Flama Condensed';
	src: url('../fonts/FlamaCondensed/Flama Cond Medium/FlamaCond-Medium.eot');
	src: url('../fonts/FlamaCondensed/Flama Cond Medium/FlamaCond-Medium.eot') format('embedded-opentype'),
	     url('../fonts/FlamaCondensed/Flama Cond Medium/FlamaCond-Medium.woff') format('woff'),
	     url('../fonts/FlamaCondensed/Flama Cond Medium/FlamaCond-Medium.ttf') format('truetype'),
	     url('../fonts/FlamaCondensed/Flama Cond Medium/FlamaCond-Medium.svg') format('svg');
	font-weight: 500;
}

@font-face {
	font-family: 'Flama Condensed';
	src: url('../fonts/FlamaCondensed/Flama Cond Bold/FlamaCond-Bold.eot');
	src: url('../fonts/FlamaCondensed/Flama Cond Bold/FlamaCond-Bold.eot') format('embedded-opentype'),
	     url('../fonts/FlamaCondensed/Flama Cond Bold/FlamaCond-Bold.woff') format('woff'),
	     url('../fonts/FlamaCondensed/Flama Cond Bold/FlamaCond-Bold.ttf') format('truetype'),
	     url('../fonts/FlamaCondensed/Flama Cond Bold/FlamaCond-Bold.svg') format('svg');
	font-weight: 600;
}