/*
 * jQuery FlexSlider v2.6.3
 * http://www.woothemes.com/flexslider/
 *
 * Copyright 2012 WooThemes
 * Free to use under the GPLv2 and later license.
 * http://www.gnu.org/licenses/gpl-2.0.html
 *
 * Contributing author: Tyler Smith (@mbmufffin)
 *
 */
/* ====================================================================================================================
 * FONT-FACE
 * ====================================================================================================================*/
@font-face {
  font-family: 'flexslider-icon';
  src: url('../fonts/flexslider-icon.eot');
  src: url('../fonts/flexslider-icon.eot?#iefix') format('embedded-opentype'), url('../fonts/flexslider-icon.woff') format('woff'), url('../fonts/flexslider-icon.ttf') format('truetype'), url('../fonts/flexslider-icon.svg#flexslider-icon') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* ====================================================================================================================
 * RESETS
 * ====================================================================================================================*/
.flex-container a:hover,
.flex-slider a:hover {
  outline: none;
}
.slides,
.slides > li,
.flex-control-nav,
.flex-direction-nav {
  margin: 0;
  padding: 0;
  list-style: none;
}
.flex-pauseplay span {
  text-transform: capitalize;
}
/* ====================================================================================================================
 * BASE STYLES
 * ====================================================================================================================*/
.flexslider {
  margin: 0;
  padding: 0;
}
.flexslider .slides > li {
  display: none;
  -webkit-backface-visibility: hidden;
}
.flexslider .slides img {
  width: 100%;
  display: block;
}
.flexslider .slides:after {
  content: "\0020";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
html[xmlns] .flexslider .slides {
  display: block;
}
* html .flexslider .slides {
  height: 1%;
}
.no-js .flexslider .slides > li:first-child {
  display: block;
}
/* ====================================================================================================================
 * DEFAULT THEME
 * ====================================================================================================================*/
.flexslider {
  margin: 0px 0px $header-spacing 0px;
  background: #fff;
  border: 4px solid #fff;
  position: relative;
  zoom: 1;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: '' 0 1px 4px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: '' 0 1px 4px rgba(0, 0, 0, 0.2);
  -o-box-shadow: '' 0 1px 4px rgba(0, 0, 0, 0.2);
  box-shadow: '' 0 1px 4px rgba(0, 0, 0, 0.2);
}
.flexslider .slides {
  zoom: 1;
}
.flexslider .slides img {
  height: auto;
  -moz-user-select: none;
}
.flex-viewport {
  max-height: 2000px;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.loading .flex-viewport {
  max-height: 300px;
}
.carousel li {
  margin-right: 5px;
}
.flex-direction-nav {
  height: 0;
}
/*
.flex-direction-nav a {
  text-decoration: none;
  display: block;
  width: 40px;
  height: 40px;
  margin: -20px 0 0;
  position: absolute;
  top: 50%;
  z-index: 10;
  overflow: hidden;
  opacity: 0;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.8);
  text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.3);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
*/

.flex-direction-nav a {
  text-decoration: none;
}

.flex-direction-nav a {
  @extend .glyphicon;
  @extend .glyphicon-menu-left;

  font-size: 40px;
  margin: -20px 0 0;
  position: absolute !important;
  top: 50% !important;
}

.flex-direction-nav a.flex-next {
  @extend .glyphicon;
  @extend .glyphicon-menu-right;
}

@media (max-width: $screen-xs-max) {
  .flex-direction-nav {
    display: none;
  }
}
/*
.flex-direction-nav a:before {
  font-family: "flexslider-icon";
  font-size: 40px;
  display: inline-block;
  content: '\f001';
  color: rgba(0, 0, 0, 0.8);
  text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.3);
}
.flex-direction-nav a.flex-next:before {
  content: '\f002';
}
.flex-direction-nav a
*/

.flex-direction-nav .flex-prev {
  left: -50px;
}
.flex-direction-nav .flex-next {
  right: -50px;
  text-align: right;
}
.flexslider:hover .flex-direction-nav .flex-prev {
  opacity: 0.7;
  left: 10px;
}
.flexslider:hover .flex-direction-nav .flex-prev:hover {
  opacity: 1;
}
.flexslider:hover .flex-direction-nav .flex-next {
  opacity: 0.7;
  right: 10px;
}
.flexslider:hover .flex-direction-nav .flex-next:hover {
  opacity: 1;
}
.flex-direction-nav .flex-disabled {
  opacity: 0!important;
  filter: alpha(opacity=0);
  cursor: default;
  z-index: -1;
}
.flex-pauseplay a {
  display: block;
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 5px;
  left: 10px;
  opacity: 0.8;
  z-index: 10;
  overflow: hidden;
  cursor: pointer;
  color: #000;
}
.flex-pauseplay a:before {
  font-family: "flexslider-icon";
  font-size: 20px;
  display: inline-block;
  content: '\f004';
}
.flex-pauseplay a:hover {
  opacity: 1;
}
.flex-pauseplay a.flex-play:before {
  content: '\f003';
}
.flex-control-nav {
  width: 100%;
  position: absolute;
  bottom: -40px;
  text-align: center;
}
.flex-control-nav li {
  margin: 0 6px;
  display: inline-block;
  zoom: 1;
  *display: inline;
}
.flex-control-paging li a {
  width: 11px;
  height: 11px;
  display: block;
  background: #666;
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  text-indent: -9999px;
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  -o-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}
.flex-control-paging li a:hover {
  background: #333;
  background: rgba(0, 0, 0, 0.7);
}
.flex-control-paging li a.flex-active {
  background: #000;
  background: rgba(0, 0, 0, 0.9);
  cursor: default;
}
.flex-control-thumbs {
  margin: 5px 0 0;
  position: static;
  overflow: hidden;
}
.flex-control-thumbs li {
  width: 25%;
  float: left;
  margin: 0;
}
.flex-control-thumbs img {
  width: 100%;
  height: auto;
  display: block;
  opacity: .7;
  cursor: pointer;
  -moz-user-select: none;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.flex-control-thumbs img:hover {
  opacity: 1;
}
.flex-control-thumbs .flex-active {
  opacity: 1;
  cursor: default;
}
/* ====================================================================================================================
 * RESPONSIVE
 * ====================================================================================================================*/
@media screen and (max-width: 860px) {
  .flex-direction-nav .flex-prev {
    opacity: 1;
    left: 10px;
  }
  .flex-direction-nav .flex-next {
    opacity: 1;
    right: 10px;
  }
}
/*
.flexslider-container {
  height: $flexslider-height;
  display: block !important;
  background-color: red;
}

.has-flexslider .flexslider-container {
  height: auto;
}*/

.flexslider {
  border: 0px;

  .flex-direction-nav {

    a {
      opacity: 0.7;
      height: 50px;
      padding: 50px;
    }

    .flex-prev {
      left: 10px;
      padding-left: 0px;
    }

    .flex-next {
      right: 10px;
      padding-right: 0px;
    }
  }

  .flex-control-nav {
    bottom: 20px;
  }

  .slides {
    .cover {
      img {
        max-width: 100%;
        width: auto;
        height: auto;
        margin: 0 auto;
      }
    }

    .center {
      padding: $default-spacing/2 0px;
      
      img {
        height: auto;
        width: auto;
        margin: 0 auto;
      }

      &.horizontal {
        img {
            max-width: 360px;
            -webkit-box-shadow: 2px 2px 10px 2px rgba(46,46,46,0.4);
            -moz-box-shadow: 2px 2px 10px 2px rgba(46,46,46,0.4);
            box-shadow: 2px 2px 10px 2px rgba(46,46,46,0.4);
          }
      }

      &.vertical {
        img {
          max-height: 360px;
          -webkit-box-shadow: 2px 2px 10px 2px rgba(46,46,46,0.4);
          -moz-box-shadow: 2px 2px 10px 2px rgba(46,46,46,0.4);
          box-shadow: 2px 2px 10px 2px rgba(46,46,46,0.4);
        }
      }
    }
  }
}

.flexslider-desktop,
.flexslider-product {
  .flexslider {
    height: $flexslider-height;

    .flex-viewport {
      max-height: $flexslider-height;
    }


    .slides {
      li {
        opacity: 0;
        -webkit-transition:opacity 200ms ease-out;
        -moz-transition:opacity 200ms ease-out;
        -o-transition:opacity 200ms ease-out;
        transition:opacity 200ms ease-out;
      }

      .cover,
      .center {
        opacity: 1;
        -webkit-transition:opacity 200ms ease-in;
        -moz-transition:opacity 200ms ease-in;
        -o-transition:opacity 200ms ease-in;
        transition:opacity 200ms ease-in;
      }
    }
  }
}

.flexslider-mobile {
  .flexslider {
    margin-top: $header-height-mobile - 2;
  }

  .flex-direction-nav {
    .flex-prev {
      left: 20px;
    }

    .flex-next {
      right: 20px;
    }
  }
}


.flexslider-desktop {
  .flexslider {
    height: auto;
  }
}

@media (min-width: $screen-sm-min) {
  .flexslider-mobile {
    display: none;
  }
}
  
@media (max-width: $screen-md-max) {
  .flexslider-desktop {
    .flexslider {
      height: auto;
    }
  }
}

@media (max-width: $screen-sm-max) {
  .flexslider-desktop {
    display: none;
  }

  .flexslider-mobile {
    display: block;
  }

  .flexslider {
    height: auto !important;
    margin: 0px 0px $default-spacing 0px;
  }

  .catalog-product-view {
    .flexslider {
      .slides {
        li {
          overflow: hidden;
        }
      
        .cover {
          img {
            max-width: none;
            width: auto;
            height: 600px;
            margin: 0 0 0 -50%;
          }
        }
        /*
        .center {
          padding: 0px $default-spacing;

          &.vertical {
            img {
              max-width: 100%;
            }
          }
        }*/
      }
    }
  }
}

@media (max-width: $screen-xs-max) {
  .flexslider-mobile {
    display: block;
    
    .flexslider {
      margin-top: 0px;
    }
  }

  .catalog-product-view {
    .flexslider {
      .slides {
        .cover {
          //height: auto !important;
          
          img {
            height: 360px;
          }
        }

        .center {
          img {
            margin-top: 0px !important;
          }
        }
      }
    }
  }
}

@media (max-width: 320px) {
  .catalog-product-view {
    .flexslider {
      .slides {
        .center {
          //padding: 0px;

          &.horizontal,
          &.vertical  {
            img {
              max-width: 280px;
              max-height: none;
            }
          }
        }
      }
    }
  }
}

.flexslider-container {
  margin-left: -15px;
  margin-right: -15px;
}