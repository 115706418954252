
.header-image-wrapper {
	display: block;
	min-height: 300px;
	margin-bottom: $header-spacing;

	.header-image {
	    //position: absolute;
	    //left: 0px;
	    //top: 0px;
	    //width: 100%;
	    //overflow: hidden;
	    position: relative;

	    img {
	    	width: 100%;
	    	height: auto;
	    }
	
	    &.header-image-press {
	    	height: 100px;
	    }

	    &.header-image-blog {
	    	height: 300px;
	    	background-color: #cfe1d0;
	    
	    	h1 {
	    		position: absolute;
	    		left: 40%;
	    		bottom: 40px;
	    		width: 20%;
	    		text-align: center;

	    		font-size: 30px;
	    	}
	    }

	    .gmap {
	    	height: 400px;
	    	width: 100%;
	    	opacity: 0.75;
	    	margin-top: $header-height;
	    }
	}
}

.page > .header-image-wrapper {
	margin-left: -15px;
	margin-right: -15px;
}

@media (max-width: $screen-xs-max) {
	.header-image-wrapper {
		min-height: 0px;
		
		.header-image {
	    	//top: $header-height-mobile;
		    .gmap {
		    	margin-top: 0px;
		    }
		}
	}
}