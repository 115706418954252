.artists {
	padding-top: 350px;

	.charlist {
		position: fixed;
		top: $header-height;
		left: 0px;
		width: 100%;
		//height: 400px;
		background-color: #fff;

		list-style: none;

		font-size: 100px;
		line-height: 125px;
		text-align: center;

		li {
			display: inline;
			padding: 0px $default-spacing;

			a {
				text-decoration: none;
			
				&.active {
					font-weight: normal;
				}
			}
		}

		padding: ($body-padding-top - $header-height) 0px $default-spacing 0px;
		margin: 0px;

		font-family: $text-condensed-family;
		font-weight: 300;
		z-index: 1000;
	}

	.list {
		list-style: none;
		margin: 0px 0px $default-spacing*3 0px;
		padding: 0px;

		li {
			overflow: hidden;
			padding: 0px;
			margin: 0px;

			&.artists-other {
				.headline {
					visibility: hidden;
					//display: none;
				}
			}

			.headline {
				display: block;
				float: left;
				font-size: 40px;
				line-height: 50px;

				width: 15%;
			}

			.names {
				width: 85%;
				display: block;
				float: left;
				padding: 0px;
				margin: 0px 0px $default-spacing*2 0px;
				a {
					text-decoration: none;
				}
				
				ul {
					width: 33%;
					float: left;
					padding: 0px 10px 0px 0px;

				}
			}
		}
	}
}

@media (max-width: $screen-sm-max) {
	.artists {
		padding-top: 140px;

		.charlist {
			top: 75px;

			font-size: 50px;
			line-height: 70px;

			height: 240px;

			padding: $default-spacing 0px; 
			li {
				padding: 0px $default-spacing/2;
			}
		}
	}
}

@media (max-width: $screen-xs-max) {
	.artists {
		padding-top: 100px;

		.charlist {
			font-size: 30px;
			line-height: 50px;

			height: 190px;

			li {
				padding: 0px $default-spacing/2;
			}
		}

		.list {
			li {
				.headline {
					float: none;
					display: block;
					margin: $default-spacing 0px $default-spacing/2 0px;
					text-align: center;
					width: 100%;
				}

				.names {
					float: none;
					display: block;
					width: 100%;
				}
			}
		}
	}
}