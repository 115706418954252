.sidebar {
	.subcategories  {

		.category {
			a {
				color: #aaa;
			}
		}

		.blog_roll {
			margin-top: $default-spacing*2;
		}
	}
}

.blog-items {
	.blog-item {
		.blog-title {
			margin-bottom: 0px;
		}
	}
}

@media (max-width: $screen-sm-max) {
	.blog-item {
		.img-responsive {
			max-width: 100% !important;
			max-height: none !important;
		}
	}
}


.blog-item {
	.image-wrapper {
		margin: $default-spacing/2 0px $default-spacing/2 0px;
	}

	.blog-title {
		margin: $default-spacing 0px $default-spacing 0px;
		a {
			text-decoration: none;
		}

		span {
			display: block;
		}
	}

	.blog-content {
		margin: 0px 0px $default-spacing*2 0px;
	}

	.comments {
		margin: $default-spacing*2 0px 0px 0px;

		list-style: none;
		margin: 0px;
		padding: 0px;

		.avatar {
			img {
				margin: 0px 0px $default-spacing/2 0px;
			}
		}

		.comment {
			small {
				margin-left: $default-spacing/2;
			}
		}
	}

	.social {
		list-style: none;
		margin: 0px;
		padding: 0px;

		li {
			display: inline-block;
		}
	}

	hr {
		margin-top: $default-spacing*2;
		margin-bottom: $default-spacing*2;
	}
}

#comment {
	min-height: 150px;
}