.dialog {
	position: fixed;
	top: 50%;
	left: 17.25%;
	width: 70%;
	//transform: translate(-50%, -50%) translate3d(0,0,0);
	display: none;

	padding: $default-spacing/2 $default-spacing $default-spacing $default-spacing;
	z-index: $zindex-dialog;

	.dialog-heading {
		text-align: right;
		margin: 0px 0px $default-spacing/2 0px;
	
		.glyphicon {
			font-size: 24px;
			cursor: pointer;
		}
	}


	&.dialog-default {		
		background: #eee;
		-webkit-box-shadow: 2px 2px 5px 0px rgba(34,34,34,0.75);
		-moz-box-shadow: 2px 2px 5px 0px rgba(34,34,34,0.75);
		box-shadow: 2px 2px 5px 0px rgba(34,34,34,0.75);
	}


	textarea {
		width: 100%;
		min-height: 150px;
		border: none;
	}

	label {
		font-weight: 300;
		margin: 0px 0px 5px 0px;
		line-height: 20px;
	}

	.dialog-input-wrapper {
		background-color: #fff;
		overflow: hidden;
		padding: $default-spacing/2;
		margin: 0px 0px $default-spacing/2 0px;

		input,
		textarea {
			border: none;
			background-color: #fff;
			float: left;
			padding: 0px;
			margin: 0px;
			font-size: 16px;
			line-height: 25px;

			&:focus {
				border: none;
				outline: none;
			}
		}

		input {
			width: 75%;
		}

		textarea {

		}

		span,
		label {
			display: block;
			float: right;
			font-size: 16px;
			margin: 0px;
			line-height: 25px;
			padding: 0px;
		}

		select {
			border: none;
			padding: 0px;
		}
	}

	&.dialog-request,
	&.dialog-contact,
	&.dialog-newsletter  {
		label {
			display: none;
		}

		.checkbox {
			label {
				display: block;
			}
		}
	}

	&.dialog-download {
		img {
			height: auto;
			margin-bottom: $default-spacing;
		}

		.btn {
			margin-bottom: $default-spacing;
		}

		.dialog-body {
			max-height: 600px;
			overflow-y: scroll;
		}
	}
}

@media (min-width: $screen-md-min) {
	.dialog {
		left: 25%;
		width: 50%
	}
}

@media (max-width: $screen-sm-max) {
	.dialog {
		left: 10%;
		width: 80%
	}
}

@media (max-width: $screen-xs-max) {
	.dialog {
		left: 0px;
		width: 100%;
		margin-top: 0px !important;
	}

	.dialog-advanced-search,
	.dialog-contact {
		top: 0px;
		height: 100%;
		overflow-y: scroll;

	}
	.dialog-advanced-search {
		select {
			height: 70px;
		}
		
	}

	.dialog-basic-search {
		top: $header-height-mobile !important;
		padding: $default-spacing !important;

		.dialog-heading {
			display: none;
		}
	}
}

/*
.show-advanced-search {
	.page {
		display: none;
	}
}*/